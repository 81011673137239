import browser from 'browser-detect';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import { environment as env } from '../../environments/environment';

import {
  routeAnimations,
  LocalStorageService,
  selectSettingsLanguage
} from '../core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '../core/settings/settings.actions';

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  searchText = 'Hledat';
  public faHome = faHome;
  year = new Date().getFullYear();
  logo = require('../../assets/logo.png').default;
  languages = ['cs', 'ru', 'en'];
  navigation = [];

  language$: Observable<string>;

  private observer: MutationObserver = null;

  ngOnDestroy() {
    this.observer.disconnect();
  }

  constructor(
    private store: Store,
    private storageService: LocalStorageService
  ) {}

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
  }

  ngAfterViewInit() {
    const node = document.querySelector('body');
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.addedNodes.length > 0 &&
          mutation.addedNodes.length > 0 &&
          mutation.addedNodes.item(0)['classList'] == 'cdk-overlay-container'
        ) {
          this.observer.disconnect();
          this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.addedNodes.length > 0) {
                let tooltip = mutation.addedNodes[0];
                while (
                  tooltip.hasChildNodes() &&
                  tooltip.firstChild.nodeType == Node.ELEMENT_NODE
                ) {
                  tooltip = tooltip.childNodes[0];
                }
                if (tooltip) {
                  tooltip['innerHTML'] = tooltip['innerHTML']
                    .replaceAll('&lt;', '<')
                    .replaceAll('&gt;', '>');
                }
              }
            });
          });
          this.observer.observe(mutation.addedNodes.item(0), {
            attributes: false,
            childList: true,
            characterData: false
          });
        }
      });
    });

    this.observer.observe(node, {
      attributes: false,
      childList: true,
      characterData: false
    });
  }

  search(searchText) {}

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }
}
