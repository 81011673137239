<div class="theme-wrapper default-theme">

  <mat-sidenav-container>

    <mat-sidenav #sidenav mode="push">
      <div class="branding">
        <span>{{ 'anms.title.short' | translate}}</span></div>
    </mat-sidenav>

    <div class="wrapper">

      <div class="toolbar" [style.position]="'fixed'" [class.mat-elevation-z4]="true">
        <mat-toolbar color="primary">

          <span routerLink="" class="branding spacer center d-inline d-sm-none"></span>
          <span routerLink="" class="branding spacer d-none d-sm-inline d-md-inline"><fa-icon [icon]="faHome"></fa-icon> {{ 'anms.title.short' | translate}}</span>

          <button mat-button  [routerLink]="'report'">
            {{'anms.report.error' | translate}}
          </button>
          <button mat-button [matMenuTriggerFor]="menu">
            {{'anms.about.project' | translate}}
          </button>

          <mat-menu #menu="matMenu">
            <div class="example-menu">
              <button class="example-menu-item" [routerLink]="'about/slan'" mat-menu-item>{{'anms.about.slan' | translate}}</button>
              <button class="example-menu-item" [routerLink]="'about/authors'" mat-menu-item>{{'anms.about.authors' | translate}}</button>
              <button class="example-menu-item" [routerLink]="'about/technical'" mat-menu-item>{{'anms.about.technical' | translate}}</button>
              <button class="example-menu-item" [routerLink]="'about/publications'" mat-menu-item>{{'anms.about.publications' | translate}}</button>
            </div>
          </mat-menu>
          <button mat-button [routerLink]="'license'">
            {{'anms.license' | translate}}
          </button>

          <span *ngIf="language$ | async as language">
            <mat-select [ngModel]="language" (selectionChange)="onLanguageSelect($event)">
              <mat-option *ngFor="let l of languages" [value]="l">
                {{ l.toUpperCase() }}
              </mat-option>
            </mat-select>
          </span>
        </mat-toolbar>
      </div>

      <div class="content" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">
        <router-outlet #o="outlet"></router-outlet>
      </div>

      <div class="footer">
        <div class="row logo">
          <div class="col-sm-12 links">
            <a href="https://www.muni.cz"
               target="_blank" rel="noopener noreferrer">
              <img class="first" src="/assets/logo_muni.png" />
            </a>
            <a href="https://www.tacr.cz"
               target="_blank" rel="noopener noreferrer">
               <img class="second" src="/assets/logo_tacr_2.png" />
            </a>
            <a href="https://www.evetech.cz/"
               target="_blank" rel="noopener noreferrer">
               <img class="third" src="/assets/logo_evetech.png" />
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 signature">
            &#169; <span class="year">{{year}}</span> - EVE Technologies s.r.o. & Masaryk University
            <br class="d-block d-sm-none" />
            <div class="licence in-mini-footer" xmlns:cc="http://creativecommons.org/ns#">
              This work is licensed under <br/>
              <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display: inline-block">Attribution-NonCommercial-NoDerivatives 4.0 International<img style="height: 22px !important; margin-left: 3px; vertical-align: text-bottom" src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"><img style="height: 22px !important; margin-left: 3px; vertical-align: text-bottom" src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"><img style="height: 22px !important; margin-left: 3px; vertical-align: text-bottom" src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1"><img style="height: 22px !important; margin-left: 3px; vertical-align: text-bottom" src="https://mirrors.creativecommons.org/presskit/icons/nd.svg?ref=chooser-v1"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav-container>

</div>
